<template>
  <main>
    <section class="container aboutUs__banner">
      <img width="100%" src="/img/banner-aboutUs.jpg" alt="banner-aboutUs" title="banner-aboutUs" />
    </section>
    <div class="container">
      <article>
        <div class="aboutUs__content">
          <h1 class="aboutUs__title">Lorem ipsum</h1>
          <v-expansion-panels
            v-model="expansion"
            accordion
            flat
            class="aboutUs__accordion"
          >
            <v-expansion-panel
              v-for="(item, index) in items"
              :key="index"
              class="aboutUs__accordion-item"
            >
              <v-expansion-panel-header hide-actions class="accordion-btn">
                <span
                  class="accordion-icon"
                  :style="
                    index === expansion
                      ? 'background-image: url(/img/icons/minus-icon.svg)'
                      : 'background-image: url(/img/icons/plus-icon.svg)'
                  "
                ></span>
                <h2>{{ item.title }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <p>
                  <span class="accordion-content-subtitle">
                    Creating quality items for everyday
                  </span>
                  We believe that everyone deserves quality. Durable use, which
                  meets the need, is what makes us happy. In doing so, we rely
                  on professional expertise and creative excellence in working
                  with felt.
                </p>

                <p>
                  TUMAR is a producer and design group. Our first product was
                  launched in 1998 by three people. Today we are proud to
                  empower 200 individuals of professional felt makers and
                  designers. We serve thousands of customers around the world by
                  making 100% natural wool products.
                </p>
                <p>
                  We draw ideas from traditional materials and craft traditions
                  of nomadic ancestors. We are inspired by the art of
                  transforming creative minds into high quality and functional
                  items of everyday. Our mission is to engage with the world by
                  modernizing felt and elevating standards of living for
                  customers and ourselves.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="aboutUs__image">
            <img src="" alt="aboutUs__image" title="aboutUs__image"/>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>
<script>
export default {
  data() {
    return {
      expansion: null,
      items: [
        { title: 'How to place an order?' },
        { title: 'How does TUMAR approach environmental issues?' },
        {
          title:
            'What international exhibitions and fairs has TUMAR participated in?',
        },
        { title: 'What kind of product does TUMAR produce?' },
        { title: 'Do you have a retail store?' },
        {
          title:
            'How can I share an idea, comment or concern with your company?',
        },
        { title: 'Partnership with TUMAR' },
      ],
    }
  },
}
</script>

<style scoped>
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
</style>
